@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Rowdies", sans-serif;
  background-color: #061C44;
  background-image: url("./assets/bg.png");
  color: white;
  background-position: center -600px;
  background-size: contain;
  background-repeat: no-repeat;
}

img,
svg,
canvas,
iframe,
video {
  max-width: 100%;
}

a,
span {
  display: inline-flexbox;
}

#root {
  padding: 100px 0;
}

@media (max-width: 499px) {
  #root {
    padding: 30px 0;
  }
}

#root .logo img {
  background-color: #131415;
  padding: 20px 10px;
  border-radius: 10px;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  height: 200px;
}

@media (min-width: 1400px) {
  .logo h1 {
    font-size: 44px;
  }
}

.logo h1 {
  margin: 20px 0;
}

.container {
  max-width: 900px;
  margin: 0 auto;
}

img:has(+img),
img:has(+img)+img {
  padding: 5px;
  width: 50%;
}

.table {
  white-space: nowrap;
  vertical-align: middle;
  --bs-table-bg: transparent;

  td,
  th {
    color: white;
    border-bottom-color: rgba(255, 255, 255, 0.2);
  }
}

img,
.img {
  flex-shrink: 0;
  border: 2px solid black;
  border-radius: 1rem;
  width: 360px;
}

p.content {
  color: rgba(255, 255, 255, 0.6);
}

ul li:not(:last-child) {
  margin-bottom: 10px;
}

ul li {
  color: #EBEBF5
}